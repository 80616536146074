import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import { Tooltip, Pagination, Popconfirm } from "antd";

import { fetchSavedReports, downloadScheduledReport, deleteSavedReport } from "store/exports/actions";
import { STATE_STATUSES } from "utils/statuses";

import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import EditReport from "components/TabsExport/EditReport";

import deleteIcn from "assets/images/delete.svg";
import exportIcn from "assets/images/export.svg";
import editIcn from "assets/images/edit.svg";
import eyeInc from "assets/images/eye.svg";
import useColorTheme from "../../hooks/useColorTheme";
import MsgPopup from "components/MsgPopup";
import { BUTTONS_TYPE } from "constants/buttons-type.constants";
const selects = [
  {
    name: "perPage",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const Report = ({ item, setReportId, setIsShowExportMsg }) => {
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();

  const downloadReport = () => {
    dispatch(downloadScheduledReport(item.id)).then((response) => {
      if (response && response.data && response.data.status !== STATE_STATUSES.READY) {
          setIsShowExportMsg(true);
      } else if (response.data && response.data.file) {
          let blob = new Blob([Buffer.from(response.data.file, 'base64')], {
              type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          });
          FileSaver.saveAs(blob, `${item.name}.xlsx`);
      }
    });
  };

  const deleteReport = () => {
    dispatch(deleteSavedReport(item.id));
  };

  return (
    <div className="report-row">
      <div style={{ width: "280px", fontFamily: "Gilroy-ExtraBold" }}>{item.name}</div>
      <div style={{ width: "140px", textTransform: "capitalize", fontFamily: "Gilroy-Medium" }}>
        {item.schedule.type ? item.schedule.type : "Not Scheduled"}
      </div>
      <div style={{ width: "120px", textAlign: "center" }}>
        <Tooltip
          overlayInnerStyle={{ color: "#000", textTransform: "capitalize" }}
          color="#fff"
          placement="top"
          title={item.user?.avatar ? `${item.user.first_name || "N/A"} ${item.user.last_name || "N/A"}` : null}
        >
          {
            item.user?.avatar ?
              <img className="avatar" src={item.user.avatar} alt="avatar" />
              :
              `${item.user.first_name || "N/A"} ${item.user.last_name || "N/A"}`

          }
        </Tooltip>
      </div>
      <div className="report-options">
        {item.user?.avatar ? null : (
          <Popconfirm
            placement="top"
            title={"Are you sure you want to delete this report?"}
            onConfirm={deleteReport}
            okText="Yes"
            cancelText="No"
          >
            <div style={{ background: "#EEF2F7" }}>
              <img src={deleteIcn} />
            </div>
          </Popconfirm>
        )}
        {item.user?.avatar ? (
          <div style={{ background: "#EEF2F7" }} onClick={() => setReportId(item.id)}>
            <img src={eyeInc} />
          </div>
        ) : (
          <div style={{ background: "#EEF2F7" }} onClick={() => setReportId(item.id)}>
            <img src={editIcn} />
          </div>
        )}
        <div style={{ background: primaryColor }} onClick={downloadReport}>
          <img src={exportIcn} />
        </div>
      </div>
    </div>
  );
};

const SavedReports = ({onClose}) => {
  const dispatch = useDispatch();
  const [isShowExportMsg, setIsShowExportMsg] = useState(false);

  const { savedReports, status } = useSelector((state) => state.exports);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [reportId, setReportId] = useState(null);
  const { primaryColor } = useColorTheme();

  useEffect(() => {
    dispatch(fetchSavedReports());
  }, []);

  const limit = page * perPage + perPage < savedReports.length ? page * perPage + perPage : savedReports.length;

  const renderData = savedReports.slice(page * perPage, limit);

  const onChangePage = (page, pageSize) => {
    setPage(page - 1);
  };

  const onChangeSize = (value) => {
    setPerPage(Number(value["perPage"]));
  };

  const closeModal = () => {
    setIsShowExportMsg(false);
    onClose();
  }

  return (
    <div style={{ position: "relative", minHeight: "120px" }}>
      {savedReports?.length ? (
        <div className="export-tab">
          {reportId ? (
            <EditReport id={reportId} setReportId={setReportId} />
          ) : (
            <>
              <div className="export-hint">
                Your saved reports are shown below, in addition to shared reports.
                <br />
                Please note that you are unable to edit reports which are shared with you.
              </div>

              <div className="export-table-header" style={{ marginTop: "45px" }}>
                <div className="export-column" style={{ width: "280px" }}>
                  Report Name
                </div>
                <div className="export-column" style={{ width: "140px" }}>
                  Schedule
                </div>
                <div className="export-column" style={{ width: "120px", textAlign: "center" }}>
                  Owner
                </div>
                <div className="export-column" style={{ width: "240px", textAlign: "right", paddingRight: "10px" }}>
                  Report Options
                </div>
              </div>
              <div className="export-table-body">
                {renderData.map((item, index) => (
                  <Report key={index} item={item} setReportId={setReportId} setIsShowExportMsg={setIsShowExportMsg} />
                ))}
              </div>
              <div className="pagimation-wrapper">
                <Pagination
                  style={{ position: "static" }}
                  className="pagination-controls"
                  total={savedReports.length}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                  pageSize={perPage}
                  current={page + 1}
                  onChange={onChangePage}
                />
                {selects.map((item, index) => (
                  <div className="per-page-box" key={index}>
                    <span>Show</span>
                    <SelectBox key={index} data={item} setSelectValue={onChangeSize} />
                    <span style={{ marginLeft: "15px" }}>Per Page</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {status === STATE_STATUSES.READY ? (
            <div className="chart-no-data static">No Scheduled Reports</div>
          ) : null}
        </>
      )}

      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      <MsgPopup 
        themeColor={primaryColor}
        buttonType={BUTTONS_TYPE.SOLID}
        onOk={closeModal}
        onCancel={closeModal}
        isShow={isShowExportMsg}
        title={'Your report is being prepared'}
        message={'You will be notified when the report is ready to download'}
      />
    </div>
  );
};

export default SavedReports;
