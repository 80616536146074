import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import useCountry from "../../hooks/useCountry";

import {
  fetchActiveCompanyMembers,
  fetchExportFilters,
  editSavedReport,
  fetchSavedReport,
  clearSavedReport,
} from "store/exports/actions";

import TemplateReport from "components/TabsExport/components/TemplateReport";

import backIcn from "assets/images/back.svg";

import { sectionsSummary } from "components/TabsExport/exportSections";

const EditReport = ({ id, setReportId }) => {
  const dispatch = useDispatch();

  const { savedReport, savedReports, companyMembers } = useSelector((state) => state.exports);

  const {
    filters: { filters },
  } = useSelector((state) => state.exports);
  const { currentCountry } = useCountry();

  const [disabled, setDisabled] = useState(false);

  const [sectionId, setSectionId] = useState("");

  const [name, setName] = useState(null);
  const [options, setOptions] = useState({
    pricing: {
      promotion: false,
      pricingMechanic: "shelfPrice",
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      productGroup: false,
    },
    content: {},
    navigation: {},
    promotions: {
      viewLogic: "daily",
      weeklyType: "average",
      snapshotDay: 1,
      grouped: false,
      viewType: "retailer",
      fillColor: "mechanic",
      productGroup: false,
    },
    rating: {},
    availability: {},
    ranking: {},
    media: {},
    assortment: {},
  });
  const [filterParams, setFilterParams] = useState(null);
  const [schedule, setSchedule] = useState(null);

  const [emails, setEmails] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const disabled = savedReports.find((item) => item.id == id).user;
    setDisabled(Boolean(disabled));
  }, [id]);

  useEffect(() => {
    dispatch(fetchActiveCompanyMembers());
    dispatch(fetchSavedReport(id)).then((res) => {
      const { data, name, emails, users, sectionId, schedule } = res.data;
      setName(name);
      setOptions((prevState) => ({
        ...prevState,
        [sectionsSummary[sectionId]]: data.options,
      }));
      setSchedule(schedule);
      setEmails(emails.length ? emails : [""]);
      setUsers(users);
      setSectionId(sectionId);

      const getData = (data) =>
        data
          .split("|")
          .filter((item) => Number(item))
          .map((item) => Number(item));

      const params = {
        sourceType: getData(data.filters.sourceType),
        category: getData(data.filters.category),
        manufacture: getData(data.filters.manufacture),
        product: getData(data.filters.product),
        productBrand: getData(data.filters.productBrand),
        productGroup: getData(data.filters.productGroup),
        timePeriod: data.filters.timePeriod,
        save: false,
      };

      setFilterParams(params);

      ///////
      dispatch(
        fetchExportFilters({
          ...{
            sourceType: data.filters.sourceType,
            category: data.filters.category,
            manufacture: data.filters.manufacture,
            product: data.filters.product,
            productBrand: data.filters.productBrand,
            productGroup: data.filters.productGroup,
          },
          ...{ watchlist: false },
        })
      );
    });
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(clearSavedReport());
    };
  }, []);

  const handleSetOptions = (name, value) => {
    setOptions((prevState) => ({
      ...prevState,
      [sectionsSummary[sectionId]]: { ...prevState[sectionsSummary[sectionId]], [name]: value },
    }));
  };

  const setScheduleType = (value) => {
    setSchedule((prevState) => ({
      ...prevState,
      ["type"]: value,
    }));
  };

  const setScheduleDays = (value) => {
    if (schedule.days.includes(value)) {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: prevState["days"].filter((item) => item !== value),
      }));
    } else {
      setSchedule((prevState) => ({
        ...prevState,
        ["days"]: [...prevState["days"], value],
      }));
    }
  };

  const handleChangeInput = (value, indexVal) => {
    const values = emails.map((item, index) => {
      if (index === indexVal) {
        return (item = value);
      }
      return item;
    });

    setEmails(values);
  };

  const handleAddInput = () => {
    setEmails((prevState) => [...prevState, ""]);
  };

  const handleDeleteInput = (indexDel) => {
    const inputs = emails.filter((item, index) => index !== indexDel);
    setEmails(inputs);
  };

  const handleSetMembers = (id) => {
    if (users.includes(id)) {
      const selected = users.filter((item) => item !== id);
      setUsers(selected);
    } else {
      setUsers((prevState) => [...prevState, id]);
    }
  };

  const queryParams = () => ({
    sourceType: filterParams.sourceType.join("|"),
    productBrand: filterParams.productBrand.join("|"),
    category: filterParams.category.join("|"),
    manufacture: filterParams.manufacture.join("|"),
    productGroup: filterParams.productGroup.join("|"),
    product: filterParams.product.join("|"),
    save: false,
    watchlist: false,
  });

  const setFiltersParams = (name, selectItems) => {
    const sendParams = { ...queryParams(), ...{ [name]: selectItems.join("|") } };

    const parsingData = (data) =>
      data
        .split("|")
        .map((item) => Number(item))
        .filter((item) => Number(item));

    const filterSections = {
      sourceType: parsingData(sendParams.sourceType),
      productBrand: parsingData(sendParams.productBrand),
      category: parsingData(sendParams.category),
      manufacture: parsingData(sendParams.manufacture),
      productGroup: parsingData(sendParams.productGroup),
      product: parsingData(sendParams.product),
    };

    dispatch(fetchExportFilters(sendParams)).then((res) => {
      const convertData = (name, data) => {
        if (name === "productGroup" && data && typeof data === "object") {
          return [...(data.userProductGroup || []), ...(data.companyProductGroup || [])];
        }
        return data;
      };

      Object.keys(filterSections).forEach((name) => {
        if (Array.isArray(filterSections[name])) {
          const filterData = convertData(name, res.data.filters[name]);
          const dataArray = Array.isArray(filterData) ? filterData : Object.values(filterData).flat();
          const selectItems = filterSections[name].filter((item) => dataArray.some(({ id }) => id === item));

          setFilterParams((prevState) => ({
            ...prevState,
            [name]: selectItems,
          }));
        }
      });
    });
  };

  const setFiltersParamsDate = (timePeriod) => {
    let isSkipNewReviews = false;
    if (isNaN(timePeriod)) {
      const timeSelected = timePeriod.split("|");
      if (timeSelected.length > 3 && timeSelected[3] === "ful") {
        handleSetOptions("isAllReviews", true);
        isSkipNewReviews = true;
      }
    }
    handleSetOptions("isSkipNewReviews", isSkipNewReviews);

    setFilterParams((prevState) => ({
      ...prevState,
      timePeriod,
    }));
  };

  const handleEdit = () => {
    dispatch(
      editSavedReport(id, {
        name,
        schedule,
        sectionId,
        options: options[sectionsSummary[sectionId]],
        filters: { ...queryParams(), ...{ timePeriod: filterParams.timePeriod }, countryId: currentCountry.countryId },
        share: {
          users,
          emails: emails[0].length ? emails : [],
        },
      })
    ).then(() => setReportId(null));
  };

  return (
    <>
      {Object.entries(savedReport).length && options && filterParams && schedule && emails && users && sectionId ? (
        <>
          <div className="back-btn-box" onClick={() => setReportId(null)}>
            <div>
              <img src={backIcn} />
            </div>
            <span>Back</span>
          </div>
          <TemplateReport
            setFiltersParamsDate={setFiltersParamsDate}
            setFiltersParams={setFiltersParams}
            handleSetMembers={handleSetMembers}
            handleDeleteInput={handleDeleteInput}
            handleAddInput={handleAddInput}
            handleChangeInput={handleChangeInput}
            setScheduleDays={setScheduleDays}
            setScheduleType={setScheduleType}
            handleSetOptions={handleSetOptions}
            name={name}
            setName={setName}
            filterParams={filterParams}
            filters={filters}
            setSectionId={setSectionId}
            sectionId={sectionId}
            options={options}
            companyMembers={companyMembers}
            users={users}
            schedule={schedule}
            emails={emails}
            disabled={disabled}
          />

          {Boolean(options[sectionsSummary[sectionId]]) &&
          Object.entries(options[sectionsSummary[sectionId]]).length ? (
            <Button
              disabled={disabled || !Boolean(name) || !Boolean(name.trim())}
              style={{ marginTop: "30px" }}
              className="export-button"
              onClick={handleEdit}
            >
              Update
            </Button>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default EditReport;
